@font-face {
  
  font-family: 'Nutmeg Headline Ultra';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-UltraBlack.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-UltraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Book';
  src: url('./assets/fonts/Nutmeg/Nutmeg-BookItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-BookItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Regular';
  src: url('./assets/fonts/Nutmeg/Nutmeg-RegularItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Book';
  src: url('./assets/fonts/Nutmeg/Nutmeg-Book.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('./assets/fonts/Nutmeg/Nutmeg-Thin.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Headline Ultra';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-UltraLight.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Headline Extra Bold Ital';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-ExtBdIta.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-ExtBdIta.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Headline';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-Thin.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Headline';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-Regular.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Headline Ultra Light Ita';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-UltLtIta.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-UltLtIta.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Ultra';
  src: url('./assets/fonts/Nutmeg/Nutmeg-UltraLight.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Headline Book';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-BookItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-BookItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('./assets/fonts/Nutmeg/Nutmeg-Regular.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Ultra';
  src: url('./assets/fonts/Nutmeg/Nutmeg-UltraLightItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-UltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Headline';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-Bold.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Headline';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Headline';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-Light.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Headline';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-Black.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Headline Extra';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-ExtraBold.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('./assets/fonts/Nutmeg/Nutmeg-Light.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('./assets/fonts/Nutmeg/Nutmeg-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Headline Regular';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-RegularItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Headline Ultra Black Ita';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-UltBlkIta.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-UltBlkIta.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('./assets/fonts/Nutmeg/Nutmeg-Bold.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('./assets/fonts/Nutmeg/Nutmeg-Black.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Headline';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Extra';
  src: url('./assets/fonts/Nutmeg/Nutmeg-ExtraBold.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('./assets/fonts/Nutmeg/Nutmeg-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Headline';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-BlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('./assets/fonts/Nutmeg/Nutmeg-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Headline Book';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-Book.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Ultra';
  src: url('./assets/fonts/Nutmeg/Nutmeg-UltraBlack.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-UltraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Nutmeg Ultra';
  src: url('./assets/fonts/Nutmeg/Nutmeg-UltraBlackItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-UltraBlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Headline';
  src: url('./assets/fonts/Nutmeg/NutmegHeadline-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/NutmegHeadline-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg Extra';
  src: url('./assets/fonts/Nutmeg/Nutmeg-ExtraBoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Nutmeg';
  src: url('./assets/fonts/Nutmeg/Nutmeg-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Nutmeg/Nutmeg-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

body {
  margin: 0;
  font-family: 'Nutmeg', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (min-width: 601px) {
  body{
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  body {
    font-size: 12px;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
